import "../App.css";

const ContactUs = () => {
  return (
    <>
      <div className="contact_us_green">
        <div className="responsive-container-block big-container">
          <div className="responsive-container-block container">
            <div
              className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-7 wk-ipadp-10 line"
              id="i69b-2"
            >
              <form className="form-box">
                <div className="container-block form-wrapper">
                  <div className="head-text-box">
                    <p className="text-blk contactus-head">Contact us</p>
                    <p className="text-blk contactus-subhead">
                      Embarking on the journey of creating Networking Tag has
                      been an incredible experience, one filled with innovation
                      and the pursuit of creating a seamless conference
                      experience. Our vision was to redefine the conference
                      experience, and the feedback from the Women in Consumer
                      Finance conference participants tells us that we're on the
                      right path.
                    </p>
                  </div>
                  <div className="responsive-container-block">
                    <div
                      className="responsive-cell-block wk-ipadp-6 wk-tab-12 wk-mobile-12 wk-desk-6"
                      id="i10mt-6"
                    >
                      <p className="text-blk input-title">FIRST NAME</p>
                      <input className="input" id="ijowk-6" name="FirstName" />
                    </div>
                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                      <p className="text-blk input-title">LAST NAME</p>
                      <input className="input" id="indfi-4" name="Last Name" />
                    </div>
                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                      <p className="text-blk input-title">EMAIL</p>
                      <input className="input" id="ipmgh-6" name="Email" />
                    </div>
                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                      <p className="text-blk input-title">PHONE NUMBER</p>
                      <input
                        className="input"
                        id="imgis-5"
                        name="PhoneNumber"
                      />
                    </div>
                    <div
                      className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12"
                      id="i634i-6"
                    >
                      <p className="text-blk input-title">
                        WHAT DO YOU HAVE IN MIND
                      </p>
                      <textarea
                        className="textinput"
                        id="i5vyy-6"
                        placeholder="Please enter query..."
                      ></textarea>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <button className="submit-btn">Submit</button>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-5 wk-ipadp-10"
              id="ifgi"
            >
              <div className="container-box">
                <div className="text-content">
                  <p className="text-blk contactus-head">Contact us</p>
                  <p className="text-blk contactus-subhead">
                    Embarking on the journey of creating NetworkingTag has been
                    an incredible experience, one filled with innovation and the
                    pursuit of creating a seamless conference experience. Our
                    vision was to redefine the conference experience, and the
                    feedback from the Women in Consumer Finance conference
                    participants tells us that we're on the right path.
                  </p>
                </div>
                <div className="workik-contact-bigbox">
                  <div className="workik-contact-box">
                    {/* <div className="phone text-box">
                      <img
                        className="contact-svg"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET21.jpg"
                      />
                      <p className="contact-text">+1258 3258 5679</p>
                    </div> */}
                    <div className="address text-box">
                      <img
                        className="contact-svg"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET22.jpg"
                      />
                      <p className="contact-text"> admin@networkingtag.com</p>
                    </div>
                    {/* <div className="mail text-box">
                      <img
                        className="contact-svg"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET23.jpg"
                      />
                      <p className="contact-text">102 street, y cross 485656</p>
                    </div> */}
                  </div>
                  <div className="social-media-links">
                    <a href="">
                      <img
                        className="social-svg"
                        id="is9ym"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-mail.svg"
                      />
                    </a>
                    <a href="">
                      <img
                        className="social-svg"
                        id="i706n"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-twitter.svg"
                      />
                    </a>
                    <a href="">
                      <img
                        className="social-svg"
                        id="ib9ve"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-insta.svg"
                      />
                    </a>
                    <a href="">
                      <img
                        className="social-svg"
                        id="ie9fx"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-fb.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
